import Header from './components/Header'
// import Footer from './components/Footer'
import Dashboard from './pages/Dashboard'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import  ProtectedRoute  from "./functions/ProtectedRoute";
import Data from './pages/Data'
import Login from './pages/Login';
import Register from './pages/Register';
import Invoice from './pages/Invoice';
import Airtime from './pages/Airtime';
import Cable from './pages/Cable';
import Electricity from './pages/Electricity';
import Exam from './pages/Exam';
import Smile from './pages/Smile';
import AirtimeToCash from './pages/AirtimeToCash';
import RechargePin from './pages/RechargePin';
import Receipt from './pages/Receipt';
import FundWallet from './pages/FundWallet/Fund-Wallet';
import Atm from './pages/FundWallet/Atm';
import Profile from './pages/Profile';
import Transaction from './pages/Transaction';
import InstantTransfer from './pages/FundWallet/Virtual-Account';
import KYC from './pages/KYC';
import Spectranet from './pages/Spectranet';
import BulkSMS from './pages/BulkSMS';
import ForgetPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import Bio from './pages/KYC/Bio';
import BankAccount from './pages/KYC/BankAccount';
import UploadID from './pages/KYC/UploadID';
import Process from './pages/KYC/Process';
import VerifyEmail from './pages/KYC/VerifyEmail';
import VerifyBVN from './pages/KYC/VerifyBVN';
import ManualFunding from './pages/FundWallet/ManualFunding';
import Coupon from './pages/FundWallet/Coupon';
import UpgradeAccount from './pages/UpgradeAccount';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Referral from './pages/Referral';
import ResetPIN from './pages/ResetPIN';
import Pricing from './pages/Pricing';
import DynamicAccount from './pages/FundWallet/DynamicAccount';
import LinkBVN from './pages/KYC/LinkBVN';
import Verification from './pages/KYC/Verification';

function App () {
  return (
      <Router>
        <Header />
        <Routes>
        <Route exact path='/register' element={<Register /> }/>
        <Route exact path='/login' element={<Login /> }/>
        <Route exact path='/forgot-password' element={<ForgetPassword /> }/>
        <Route exact path='/reset-password' element={<ResetPassword /> }/>
          <Route exact path='/' element={<ProtectedRoute Component={Dashboard} />}  />
          <Route exact path='/data' element={<ProtectedRoute Component={Data} />} />
          <Route exact path='/airtime' element={<ProtectedRoute Component={Airtime} />} />
          <Route exact path='/cable' element={<ProtectedRoute Component={Cable} />} />
          <Route exact path='/invoice' element={<ProtectedRoute Component={Invoice} />} />
          <Route exact path='/electricity' element={<ProtectedRoute Component={Electricity} />} />
          <Route exact path='/exam' element={<ProtectedRoute Component={Exam} />} />
          <Route exact path='/recharge-pin' element={<ProtectedRoute Component={RechargePin} />} />
          <Route exact path='/airtime-cash' element={<ProtectedRoute Component={AirtimeToCash} />} />
          <Route exact path='/smile' element={<ProtectedRoute Component={Smile} />} />
          <Route exact path='/receipt' element={<ProtectedRoute Component={Receipt} />} />
          <Route exact path='/spectranet' element={<ProtectedRoute Component={Spectranet} />} />
          <Route exact path='/bulk-sms' element={<ProtectedRoute Component={BulkSMS} />} />          
          <Route exact path='/profile' element={<ProtectedRoute Component={Profile} />} />
          <Route exact path='/transaction' element={<ProtectedRoute Component={Transaction} />} />
          <Route exact path='/change-password' element={<ProtectedRoute Component={ChangePassword} />} />
          <Route exact path='/upgrade-account' element={<ProtectedRoute Component={UpgradeAccount} />} />
          <Route exact path='/referral/log' element={<ProtectedRoute Component={Referral} />} />
          <Route exact path='/reset-pin' element={<ProtectedRoute Component={ResetPIN} />} />
          <Route exact path='/pricing' element={<ProtectedRoute Component={Pricing} />} />

          {/* KYC Route */}
          <Route exact path='/kyc' element={<ProtectedRoute Component={KYC} />} />
          <Route exact path='/kyc/complete-profile' element={<ProtectedRoute Component={Bio} />} />
          <Route exact path='/kyc/bank-account' element={<ProtectedRoute Component={BankAccount} />} />
          <Route exact path='/kyc/upload-id' element={<ProtectedRoute Component={UploadID} />} />
          <Route exact path='/kyc/process' element={<ProtectedRoute Component={Process} />} />
          <Route exact path='/kyc/verify-email' element={<ProtectedRoute Component={VerifyEmail} />} />
          <Route exact path='/kyc/bvn-verification' element={<ProtectedRoute Component={VerifyBVN} />} />
          <Route exact path='/kyc/bvn-link' element={<ProtectedRoute Component={LinkBVN} />} />
          <Route exact path='/kyc/verification' element={<ProtectedRoute Component={Verification} />} />

          {/* Fund Wallet */}
          <Route exact path='/fund-wallet' element={<ProtectedRoute Component={FundWallet} />} />
          <Route exact path='/fund-wallet/atm' element={<ProtectedRoute Component={Atm} />} />
          <Route exact path='/fund-wallet/coupon' element={<ProtectedRoute Component={Coupon} />} />
          <Route exact path='/fund-wallet/manual-funding' element={<ProtectedRoute Component={ManualFunding} />} />
          <Route exact path='/fund-wallet/virtual-account' element={<ProtectedRoute Component={InstantTransfer} />} />
          <Route exact path='/fund-wallet/dynamic-account' element={<ProtectedRoute Component={DynamicAccount} />} />

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound/>} />

        </Routes>
        {/* <Footer /> */}
      </Router>
  )
}

export default App
